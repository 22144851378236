import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { LegalIndex } from '@truphone/legal-components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import { formatDate } from '../../utils'

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

function LegalIndexPage({
  data: { datoCmsSite, datoCmsLegalIndex, allDatoCmsLegalArticle },
  pageContext: { menu, footer, cookiePrompt, locale }
}) {
  const [pageLocale, setPageLocale] = useState(locale)
  useEffect(() => {
    if (locale === 'en') {
      setPageLocale(window.navigator.language)
    }
  }, [])

  const groups = Object.entries(
    groupBy('articleType')(
      allDatoCmsLegalArticle.edges
        .sort((a, b) => a.node.articleType?.position - b.node.articleType?.position)
        .map((edge) => ({
          articleType: edge.node.articleType?.title,
          title: edge.node.title,
          excerpt: edge.node.subtitle,
          publishedDate: formatDate(edge.node.meta.firstPublishedAt, pageLocale),
          url: `${datoCmsLegalIndex.url}${edge.node.slug}/`,
          position: edge.node.position
        }))
    )
  ).map((group) => ({
    title: group[0],
    pages: group[1].sort((a, b) => a.position - b.position)
  }))

  return (
    <>
      <HelmetDatoCms
        seo={datoCmsLegalIndex.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <TailwindLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt}>
        <LegalIndex
          title={datoCmsLegalIndex.title}
          groups={groups}
          footerText={datoCmsLegalIndex.footerText}
        />
      </TailwindLayout>
    </>
  )
}

export default LegalIndexPage

export const Query = graphql`
  query($id: String!, $locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsLegalIndex(locale: { eq: $locale }, originalId: { eq: $id }) {
      seoMetaTags {
        tags
      }
      title
      url
      footerText
    }
    allDatoCmsLegalArticle(filter: { slug: { ne: null }, locale: { eq: $locale } }) {
      edges {
        node {
          articleType {
            position
            title
          }
          title
          subtitle
          meta {
            firstPublishedAt
          }
          slug
          position
        }
      }
    }
  }
`
