import React from 'react'
import { HeadingH1, HeadingH6, HeadingH5Underline, BodySmall } from '@truphone/core-components'
import '../style.css'
import { LinkWrap } from '@truphone/gatsby-wrappers'

function LegalIndex(props) {
  return (
    <div className="bg-grey-100 pt-28">
      <div className="container mx-auto px-6 pb-14">
        <HeadingH1 className="mb-12">{props.title}</HeadingH1>
        <div className="flex flex-wrap">
          {props.groups &&
            props.groups.map((group, i) => (
              <div key={`legal-${i}`} className="lg:w-1/3">
                <HeadingH6 className="mb-8">{group.title}</HeadingH6>
                {group.pages &&
                  group.pages.map((page, i) => (
                    <div key={`legal-page-${i}`} className="mb-10 mr-6">
                      <BodySmall className="mb-2">{page.publishedDate}</BodySmall>
                      <LinkWrap href={page.url}>
                        <HeadingH5Underline>{page.title}</HeadingH5Underline>
                        <BodySmall className={'mt-1'}>{page.excerpt}</BodySmall>
                      </LinkWrap>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
      {props.footerText && (
        <div className="bg-white py-10">
          <div
            className="p-margin td-table text-base container mx-auto px-6"
            dangerouslySetInnerHTML={{ __html: props.footerText }}
          />
        </div>
      )}
    </div>
  )
}

export default LegalIndex
