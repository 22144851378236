import React from 'react'
import { HeadingH2, HeadingH3Light, BodySmall } from '@truphone/core-components'
import '../style.css'

function LegalArticle(props) {
  return (
    <div className="bg-grey-100 pt-28">
      <div className="article-width mx-auto px-6 pb-14">
        <HeadingH2 className="mb-1 font-bold">{props.title}</HeadingH2>
        {props.subtitle && <HeadingH3Light>{props.subtitle}</HeadingH3Light>}

        <BodySmall className="mt-6 mb-7">
          <span>{props.publishedDate}</span>
          {props.readingTime && (
            <>
              {' '}
              • <span>{props.readingTime}</span>
            </>
          )}
        </BodySmall>
        <div
          className="text-base p-margin ul-margin list-disc list-numbers table-style link-style"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  )
}

export default LegalArticle
